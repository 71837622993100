// import css //
import './App.css';

// import images // for Component / Mockup Design view 
import batmanImg from "./BATMAN_objfit.png";
import cImg from "./cinemaui.png";
import tImg from "./touristsui.png";
import fImg from "./fitness.png";

// import video bgs // for Component / Mockup Design view 
import batman from "./batman.mp4";
import cinemaui from "./cinemaui.mp4";
import touristsui from "./touristsui.mp4";
import pbs from "./pbs.mp4";
import av from "./av.mp4";

// import muse video bg  // for background view
import muse from "./pbs.mp4";

// import black video bg // for background view
import black from "./black.mp4";

// import useEffect, useRef, useState //
import {  useEffect, useRef, useState  } from "react";

// function !important //
function App() {
   
   // useState !important //
   let [ID, setID] = useState(23);

   // useEffect loader // Highest Level // !important //
   useEffect(() => {
      let timeoutId = setTimeout(() => { 
         loadRef.current.style.display = 'none';
      }, 7000); return () => clearTimeout(timeoutId);
   }, [] ); // VERY !important code // remember // add , [] to end of code so that it runs only ONCE!!! on mount //
   
   // useRef // all mouse overs !important //
   const loadRef = useRef(null);
   const myRef = useRef(null);
   const videoRef = useRef(null);
   const [videoSrc, setVideoSrc] = useState(muse);
   const isCD3DMUVisible = useRef(null);
   const isCDMUVisible = useRef(null);
   const isCDNOVIDMUVisible = useRef(null);
   const isAboutDataVisible = useRef(null);
   const isCDMUBGVisible = useRef(null);

   // fs Highlights
   let Crtl = useRef(null);// all links
   let fs3D1 = useRef(null);// mock
   let fs1 = useRef(null);// mock
   let fs2 = useRef(null);// mock
   let fs3 = useRef(null);
   let fs4 = useRef(null);
   let fs5 = useRef(null);
   let fs6 = useRef(null);
   let fs7 = useRef(null);// mock
   let fs8 = useRef(null);
   let fs9 = useRef(null);
   let fs10 = useRef(null);

   // fs onMouseOvers - shows images from App.css file
   const onOvr3D1 = (id) => { setID(301); setVideoSrc(black); myRef.current.classList.add('bgImg3D1'); } // cinemaui
   let onOut3D1 = () => myRef.current.classList.remove('bgImg3D1');// all mouse overs !important //

   const onOvr1 = (id) => { setID(0); setVideoSrc(black); myRef.current.classList.add('bgImg1'); } // cinemaui
   let onOut1 = () => myRef.current.classList.remove('bgImg1');// all mouse overs !important //

   const onOvr2 = () => {setID(1); setVideoSrc(black); myRef.current.classList.add('bgImg2'); } // touristsui
   let onOut2 = () => myRef.current.classList.remove('bgImg2');// all mouse overs !important //

   const onOvr3 = () => {setID(2); setVideoSrc(black); myRef.current.classList.add('bgImg3'); } // coca-cola
   let onOut3 = () => myRef.current.classList.remove('bgImg3');// all mouse overs !important //

   const onOvr4 = () => {setID(3); setVideoSrc(pbs); } // pbs.org
   let onOut4 = () => setVideoSrc(black);// all mouse overs !important //

   const onOvr5 = () => {setID(4); setVideoSrc(av); } // av
   let onOut5 = () => setVideoSrc(black);// all mouse overs !important //

   const onOvr6 = () => {setID(5); setVideoSrc(black); myRef.current.classList.add('bgImg6'); } // corpay
   let onOut6 = () => myRef.current.classList.remove('bgImg6');// all mouse overs !important //

   const onOvr7 = () => {setID(6); setVideoSrc(black); myRef.current.classList.add('bgImg7'); } // fitness
   let onOut7 = () => myRef.current.classList.remove('bgImg7');// all mouse overs !important //

   const onOvr8 = () => {setID(7); setVideoSrc(black); myRef.current.classList.add('bgImg8'); } // productiv
   let onOut8 = () => myRef.current.classList.remove('bgImg8');// all mouse overs !important //

   const onOvr9 = () => {setID(8); setVideoSrc(black); myRef.current.classList.add('bgImg9'); } // americanv
   let onOut9 = () => myRef.current.classList.remove('bgImg9');// all mouse overs !important //

   const onOvr10 = () => {setID(9); setVideoSrc(black); myRef.current.classList.add('bgImg10'); } // milliken
   let onOut10 = () => myRef.current.classList.remove('bgImg10');// all mouse overs !important //

   // special about hover 
   const aboutHover = () => { setID(23); }

   // special bg hover //
   const bgOvr = () => { setVideoSrc(muse); setID(24); }

   // special loaderbgOvr hover // force image load on mockup //
   const loaderbgOvr = () => {
    if (ID === 0) {
        myRef.current.classList.add('bgImg1');  
      } else if (ID === 1) {
        myRef.current.classList.add('bgImg2');  
      } else if (ID === 6) {
        myRef.current.classList.add('bgImg7');  
      } else if (ID === 301) {
        myRef.current.classList.add('bgImg3D1');  
      }  
   }

   // click // show3DMockVideo //
   const show3DMockVideo = () => {
      // 0
      let timeout0 = setTimeout(() => {
         Crtl.current.style.display = 'none'; 
      }, 4500);// 4s
      // 1 if conditions highlight text click for MockUps
      if (ID === 0) { 
         fs1.current.style.opacity = 1; 
      }  else if (ID === 1) { 
         fs2.current.style.opacity = 1; 
      }  else if (ID === 6) { 
         fs7.current.style.opacity = 1; 
      }  else if (ID === 301) { 
         fs3D1.current.style.opacity = 1; 
      }  
      loadRef.current.style.display = 'block'; 
      loadRef.current.style.opacity = 0;
      // 2
      let timeout1 = setTimeout(() => {
          videoRef.current.pause(); 
          loadRef.current.style.opacity = 1;
      }, 4000);// 4s
      // 3
      let timeout2 = setTimeout(() => {
          isCD3DMUVisible.current.style.display = 'block';// change cd here
          isCDMUBGVisible.current.style.display = 'block';// change cd here
      }, 11000);// 11s
      // 4
      let timeout3 = setTimeout(() => {
          loadRef.current.style.opacity = 0; 
      }, 15000);// 15s
      // 5
      let timeout4 = setTimeout(() => {
          loadRef.current.style.display = 'none';
      }, 16000);// 16s
      return () => { 
          clearTimeout(timeout0); 
          clearTimeout(timeout1); 
          clearTimeout(timeout2); 
          clearTimeout(timeout3);
          clearTimeout(timeout4);
      };
   }

   // click // showMockVideo //
   const showMockVideo = () => {
      // 0
      let timeout0 = setTimeout(() => {
         Crtl.current.style.display = 'none'; 
      }, 4500);// 4s
      // 1 if conditions highlight text click for MockUps
      if (ID === 0) { 
         fs1.current.style.opacity = 1; 
      }  else if (ID === 1) { 
         fs2.current.style.opacity = 1; 
      }  else if (ID === 6) { 
         fs7.current.style.opacity = 1; 
      }  else if (ID === 301) { 
         fs3D1.current.style.opacity = 1; 
      }
      loadRef.current.style.display = 'block'; 
      loadRef.current.style.opacity = 0;
      // 2
      let timeout1 = setTimeout(() => {
          videoRef.current.pause(); 
          loadRef.current.style.opacity = 1;
      }, 4000);// 4s
      // 3
      let timeout2 = setTimeout(() => {
          isCDMUVisible.current.style.display = 'block';// change cd here
          isCDMUBGVisible.current.style.display = 'block';// change cd here
      }, 11000);// 11s
      // 4
      let timeout3 = setTimeout(() => {
          loadRef.current.style.opacity = 0; 
      }, 15000);// 15s
      // 5
      let timeout4 = setTimeout(() => {
          loadRef.current.style.display = 'none';
      }, 16000);// 16s
      return () => { 
          clearTimeout(timeout0);
          clearTimeout(timeout1); 
          clearTimeout(timeout2); 
          clearTimeout(timeout3);
          clearTimeout(timeout4);
      };
   }

   // click // showMockNoVideo //
   const showMockNoVideo = () => {
      // 0
      let timeout0 = setTimeout(() => {
         Crtl.current.style.display = 'none'; 
      }, 4500);// 4s
      // 1 if conditions 
      if (ID === 0) { 
         fs1.current.style.opacity = 1; 
      }  else if (ID === 1) { 
         fs2.current.style.opacity = 1; 
      }  else if (ID === 6) { 
         fs7.current.style.opacity = 1; 
      }  else if (ID === 301) { 
         fs3D1.current.style.opacity = 1; 
      }
      loadRef.current.style.display = 'block'; 
      loadRef.current.style.opacity = 0;
      // 2
      let timeout1 = setTimeout(() => {
          videoRef.current.pause(); 
          loadRef.current.style.opacity = 1;
      }, 4000);// 4s
      // 3
      let timeout2 = setTimeout(() => {
          isCDNOVIDMUVisible.current.style.display = 'block';// change cd here
          isCDMUBGVisible.current.style.display = 'block';// change cd here
      }, 11000);// 11s
      // 4
      let timeout3 = setTimeout(() => {
          loadRef.current.style.opacity = 0; 
      }, 15000);// 15s
      // 5
      let timeout4 = setTimeout(() => {
          loadRef.current.style.display = 'none';
      }, 16000);// 16s
      return () => { 
          clearTimeout(timeout0); 
          clearTimeout(timeout1); 
          clearTimeout(timeout2); 
          clearTimeout(timeout3);
          clearTimeout(timeout4);
      };
   }

   // click // showAboutData // working code 
   const showAboutData = () => { 
      // 1
      loadRef.current.style.display = 'block'; 
      loadRef.current.style.opacity = 0;
      // 2
      let timeout1 = setTimeout(() => {
         loadRef.current.style.opacity = 1;
         videoRef.current.pause(); 
      }, 4000);// 4s
      // 3
      let timeout2 = setTimeout(() => {
        if (isAboutDataVisible && isCDMUBGVisible) {
          loadRef.current.style.opacity = 0; 
          isAboutDataVisible.current.style.display = 'block';// change cd here
          isCDMUBGVisible.current.style.display = 'block';// change cd here
        }
      }, 11000);// 11s
      // 4
      let timeout3 = setTimeout(() => {
         loadRef.current.style.display = 'none';
      }, 15000);// 15s
      // 5
      let timeout4 = setTimeout(() => {
         loadRef.current.style.display = 'none';
      }, 16000);// 16s
      return () => { 
          clearTimeout(timeout1); 
          clearTimeout(timeout2); 
          clearTimeout(timeout3);
          clearTimeout(timeout4);
      };
   }

   // click // noShow //
   const noShow = (id) => {
      if (isCDMUVisible) {
          Crtl.current.style.display = 'block'; 
          isCD3DMUVisible.current.style.display = 'none';
          isCDMUVisible.current.style.display = 'none';
          isCDMUBGVisible.current.style.display = 'none';
          isCDNOVIDMUVisible.current.style.display = 'none';
          isAboutDataVisible.current.style.display = 'none';
          videoRef.current.play(); setVideoSrc(pbs);
          myRef.current.classList.remove('bgImg1'); 
          myRef.current.classList.remove('bgImg2'); 
          myRef.current.classList.remove('bgImg7');  
          myRef.current.classList.remove('bgImg3D1');  
      }
   }

   // click // fsHREF //
   const fsHREF = () => {
      // if conditions 
      if (ID === 2) { 
         window.open('https://careers.coca-colacompany.com', '_blank');
         fs3.current.style.opacity = 1; 
      }  else if (ID === 3) { 
         window.open('https://www.gpb.org', '_blank');
         fs4.current.style.opacity = 1; 
      }  else if (ID === 4) { 
         window.open('https://www.appvault.com', '_blank');
         fs5.current.style.opacity = 1; 
      }  else if (ID === 5) { 
         window.open('https://www.corpay.com', '_blank');
         fs6.current.style.opacity = 1; 
      }  else if (ID === 7) { 
         window.open('https://productiv.com/resources', '_blank');
         fs8.current.style.opacity = 1; 
      }  else if (ID === 8) { 
         window.open('https://www.americanvalve.com', '_blank');
         fs9.current.style.opacity = 1; 
      }  else if (ID === 9) { 
         window.open('https://milliken.com', '_blank');
         fs10.current.style.opacity = 1; 
      }
   }

   //* myArray data !important *// // useState !important //
   let myArray = [
      { id: 0, cl:"Project: Cinema", rl:"My Role: UX UI Designer", sw:"Software Used: Adobe XD, Photoshop, Illustrator ... ", im: cImg, vi: cinemaui },
      { id: 1, cl:"Project: Tourists", rl:"My Role: UX UI Designer", sw:"Software Used: Adobe XD, Photoshop, Illustrator ... ", im: tImg, vi: touristsui },
      { id: 2, cl:"Project: Coca-Cola", rl:"My Role: Digital Developer", sw:"Software Used: Tableau ... " },
      { id: 3, cl:"Project: Gpb.org", rl:"My Role: Digital Developer", sw:"Software Used: Drupal, Acquia Cloud ... " },
      { id: 4, cl:"Project: AppVault", rl:"My Role: Client Side Developer", sw:"Software Used: Wordpress, Drupal, MySQL, AWS ... " },
      { id: 5, cl:"Project: Corpay", rl:"My Role: UX UI Designer Developer", sw:"Software Used: Unily CMS, Adobe XD, Photoshop, Illustrator ... " },
      { id: 6, cl:"Project: Fitness", rl:"My Role: UX UI Designer", sw:"Software Used: Adobe XD, Photoshop, Illustrator ... ", im: fImg },
      { id: 7, cl:"Project: Productiv", rl:"My Role: Digital Developer", sw:"Software Used: Wordpress, WP Engine, Adobe XD, Photoshop, Illustrator ... " },
      { id: 8, cl:"Project: American Valve", rl:"My Role: Digital Developer", sw:"Software Used: Wordpress, Adobe XD, Photoshop, Illustrator ..." },
      { id: 9, cl:"Project: Milliken", rl:"My Role: UX UI Designer", sw:"Software Used: Adobe XD, Photoshop, Illustrator ..." },
      { id: 23, a:"DIGITAL-DEVELOPER", 
                b:"UX / UI Interactive Designer, Creative Director, Digital Artist, Software Developer ...", 
                c:"A list of Industry software used: VS Code, Zbrush, Autodesk, Unity, PS, AI, Complete Adobe CS ...",
                d:"Syntax Practice: HTML, CSS, Vanilla JS, TS, Node JS, JavaScript Libraries - Frameworks, JQuery, React, Angular, Express, MongoDB ..." },
      { id: 24, hp:"This is the matrix 2024" },
      { id: 301, cl:"Project: Batman", rl:"My Role: 3D Artist / Designer", sw:"Software Used: ZBrush & Adobe PS ", im: batmanImg, vi: batman }
   ]
   
   // Switch Client Data In Component === {ID} // !important // take newArray & match all ids === ID
   let results = myArray.find( newArray => newArray.id === ID ) 

   // Switch Client Data In Component === {ID} // CD // video & image.png !important design 1
   function ClientData3DImg () {
      return <div> 
         {/* closeData */}
         <div className="closeData" onClick={() => noShow()}></div>

         {/* cdImg */}
         <img id='cd3DImg' key={results.id} src={results.im} alt={results.alt} /> 

         <br/> <br/>

         {/* inner */}
         <div className='inner'> <center>
            <video playsInline={true} webkit-playsinline="true" id='cd3DVid' muted autoPlay loop={true} src={results.vi} type="video/mp4" /></center>
         </div>
         
         <br/> 

         {/* highLightedTxt */}
         <div id='highLightedTxt'>
            <div className='cImgB'></div>
            { results.cl } <br/> 
            { results.rl } <br/> 
            { results.sw } 
         </div>

         {/* brand® */}
         <p className="brandblack"> 2024 Thakope Lab </p>
      </div>
   };

   // Switch Client Data In Component === {ID} // CD // video & image.png !important design 1
   function ClientDataImg () {
      return <div> 
         {/* closeData */}
         <div className="closeData" onClick={() => noShow()}></div>

         {/* inner */}
         <div className='inner'>
            <video playsInline={true} webkit-playsinline="true" id='cdVid' muted autoPlay loop={true} src={results.vi} type="video/mp4" />
         </div>

         <br/> <br/>

         {/* cdImg */}
         <img id='cdImg' key={results.id} src={results.im} alt={results.alt} /> 
         
         <br/> 

         {/* highLightedTxt */}
         <div id='highLightedTxt'>
            <div className='cImgB'></div>
            { results.cl } <br/> 
            { results.rl } <br/> 
            { results.sw } 
         </div>

         {/* brand® */}
         <p className="brandblack"> 2024 Thakope Lab </p>
      </div>
   };

   // Switch Client Data In Component === {ID} // CD // just image.png & no video !important design 2
   function ClientDataNoVid () {
      return <div> 
         {/* closeData */}
         <div className="closeData" onClick={() => noShow()}></div>

         {/* cdImg */}
         <img id='cdImg' key={results.id} src={results.im} alt={results.alt} />

         {/* expandEl */}
         <div className='expandEl'></div> 

         {/* highLightedTxt */}
         <div id='highLightedTxt'>
            { results.cl } <br/> 
            { results.rl } <br/> 
            { results.sw } 
         </div>

         {/* brand® */}
         <p className="brandblack"> 2024 Thakope Lab </p>
      </div>
   };

   // Switch Client Data In Component === {ID} // CD // data only !important onMouseOver -- onMouseOver -- onMouseOver
   function ClientDataTxt () {
      return <div> 
         { results.cl } <br/> 
         { results.rl } <br/> 
         { results.sw } 
         <span style={{ opacity: 0 }}>{ results.hp }</span>
      </div>
   };

   // About Data In Component === {ID} // ABC // data only !important about me info
   function AboutDataTxt () {
      return <div> 
         {/* closeData */}
         <div className="closeData" onClick={() => noShow()}></div>

         {/* specialhighLightedTxtCon */}
         <div id='specialhighLightedTxtCon'>
            <span className='specialhighLightedTxt'> { results.a } </span> 

            <br/> <br/> 

            {/* spaceIt */}
            <div className='spaceIt'> { results.b } </div> 
            <div className='spaceIt'> { results.c } </div>
            <div className='spaceIt'> { results.d } </div>
         </div>

         {/* brand® */}
         <p className="specialbrandblack"> 2024 Thakope Lab </p>
      </div>
   };

   // return code  //// !important //// return code //
   return (
      <div id='AppCon'>  

         {/* UPDATING-CODE */}
         <div id='UPDATING-CODE'><p> UPDATING CODE </p></div>
         
         {/* hidden */} { ID } {/* hidden */}

         {/* loader */}
         <div ref={loadRef} id='loaderBG' className='loaderBG' onMouseOver={loaderbgOvr}>
             <div className="loaderIMG"></div>
         </div>

         {/* Design Mock Ups // ClientData3DImg & ClientDataImg & ClientDataNoVid & AboutDataTxt & CDMockUpsBG */}
         <div ref={isCD3DMUVisible} id='ClientData3DMockUps'><ClientData3DImg /></div>
         <div ref={isCDMUVisible} id='ClientDataMockUps'><ClientDataImg /></div>
         <div ref={isCDNOVIDMUVisible} id='ClientDataNoVidMockUps'><ClientDataNoVid /></div>
         <div ref={isAboutDataVisible} id='AboutDataCon'><AboutDataTxt /></div>
         <div ref={isCDMUBGVisible}id='CDMockUpsBG'></div>

         {/* logo */}
         <div id='logo' className='logo'></div> 

         {/* font style & onMouseOver & onMouseOut & onClicks */}
         <ul ref={Crtl}>
            {/* // 3D mock // */}
            <li ref={fs3D1} className='fs' onMouseOver={onOvr3D1} onMouseOut={onOut3D1} onClick={ () => show3DMockVideo() }> Batman </li>

            {/* // XD mock // */}
            <li ref={fs1} className='fs' onMouseOver={onOvr1} onMouseOut={onOut1} onClick={ () => showMockVideo() }> Cinema </li>
            <li ref={fs2} className='fs' onMouseOver={onOvr2} onMouseOut={onOut2} onClick={ () => showMockVideo() }> Tourists </li>

            {/* // href // */}
            <li ref={fs3} className='fs' onMouseOver={onOvr3} onMouseOut={onOut3} onClick={ () => fsHREF() }> Coca-Cola </li>
            <li ref={fs4} className='fs' onMouseOver={onOvr4} onMouseOut={onOut4} onClick={ () => fsHREF() }> Gpb.org </li> 
            <li ref={fs5} className='fs' onMouseOver={onOvr5} onMouseOut={onOut5} onClick={ () => fsHREF() }> Appvault </li> 
            <li ref={fs6} className='fs' onMouseOver={onOvr6} onMouseOut={onOut6} onClick={ () => fsHREF() }> Corpay </li> 

            {/* // XD mock // */}
            <li ref={fs7} className='fs' onMouseOver={onOvr7} onMouseOut={onOut7} onClick={ () => showMockNoVideo() }> Fitness </li>

            {/* // href // */}
            <li ref={fs8} className='fs' onMouseOver={onOvr8} onMouseOut={onOut8} onClick={ () => fsHREF() }> Productiv </li> 
            <li ref={fs9} className='fs' onMouseOver={onOvr9} onMouseOut={onOut9} onClick={ () => fsHREF() }> A.Valve </li>
            <li ref={fs10} className='fs' onMouseOver={onOvr10} onMouseOut={onOut10} onClick={ () => fsHREF() }> Milliken </li>
         </ul>

         {/* brand & About Data */} 
         <p className="brand" onMouseOver={aboutHover}> 
            <span id='brandTxt'> 2024 Thakope Lab </span>
            <span id='span'> &#x2022; </span> 
            <span id='mouseOverAboutBtn' onClick={ () => showAboutData() }> About </span>
         </p>

         {/* clientData Component ** Text only ** is fullscreen */}
         <div id='ClientData' onMouseOver={bgOvr}>
            <div id='isCdVisible'> 
               <ClientDataTxt />
            </div>
         </div>

         {/* fullsccreen imgBG */}
         <div ref={myRef} id='imgBG' className="imgBG"></div>

         {/* fullsccreen videoBG */}
         <video ref={videoRef} playsInline={true} webkit-playsinline="true" id='vidBG' muted autoPlay loop={true} src={videoSrc} type="video/mp4"/>
         
      </div>
   );
};

export default App;